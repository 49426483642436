"use client";

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Snackbar,
  Link,
  useMediaQuery,
  useTheme,
  Fade,
} from "@mui/material";
import CookieIcon from "@mui/icons-material/Cookie";

export default function CookieAlert() {
  const [open, setOpen] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const cookieConsent = localStorage.getItem("cookieConsent");
    if (!cookieConsent) {
      // Delay the render of the cookie alert
      const timer = setTimeout(() => {
        setShouldRender(true);
      }, 2000); // 2 seconds delay, adjust as needed

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    if (shouldRender) {
      setOpen(true);
    }
  }, [shouldRender]);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setOpen(false);
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "false");
    setOpen(false);
    // Here you might want to disable certain tracking features
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={open}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 1000 }} // 1 second fade-in animation
    >
      <Box
        sx={{
          backgroundColor: "background.paper",
          color: "text.primary",
          borderRadius: 2,
          boxShadow: 3,
          p: 2,
          maxWidth: 500,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CookieIcon sx={{ fontSize: 40, color: "primary.main", mb: 2 }} />
        <Typography variant="h6" gutterBottom align="center">
          Nosotros usamos cookies!
        </Typography>
        <Typography variant="body2" paragraph align="center">
          Este website utiliza cookies y tecnologías similares para mejorar tu
          experiencia de navegación, analizar el tráfico del sitio y
          proporcionar contenido y anuncios personalizados.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: 2,
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleAccept}
            fullWidth={isMobile}
          >
            Acceptar todas las cookies
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDecline}
            fullWidth={isMobile}
          >
            Rechazar
          </Button>
        </Box>
        <Typography variant="body2" sx={{ mt: 2, textAlign: "center" }}>
          Para obtener más información, consulta nuestra{" "}
          <Link href="/contact/privacy-policy" color="primary">
            Política de privacidad
          </Link>
          .
        </Typography>
      </Box>
    </Snackbar>
  );
}
