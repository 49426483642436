"use client";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import Image from "next/image";
import Link from "next/link";
import { IRoute, routes } from "@/router/routes";

const pages = ["Empleos", "Contacto", "Publica ofertas gratis !"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function WebsiteAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl" disableGutters>
        <Toolbar>
          <Box
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              flexGrow: 1,
            }}
          >
            <Link href="/">
              <Image
                src="/logo.svg"
                alt="buscaempleo logo"
                height={50}
                width={300}
                priority
              />
            </Link>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {Object.values(routes).map((route) => {
                if (!route.onNavigation) return;
                return (
                  <Link
                    key={route.name}
                    href={route.path}
                    style={{
                      textDecoration: "none",
                      color: "#000",
                    }}
                  >
                    <MenuItem>
                      <Typography textAlign="center">{route.name}</Typography>
                    </MenuItem>
                  </Link>
                );
              })}
            </Menu>
          </Box>

          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              justifyContent: "center",
            }}
          >
            <Link href="/">
              <Image
                src="/logo.svg"
                alt="buscaempleo logo"
                height={40}
                width={250}
                priority
              />
            </Link>
          </Box>

          <Box sx={{ display: { xs: "none", md: "flex" } }} gap={2} mr={2}>
            {Object.values(routes).map((route, i) => {
              if (!route.onNavigation) return;
              return (
                <Button
                  key={route.name}
                  color="secondary"
                  onClick={handleCloseNavMenu}
                  variant={route === routes.companies ? "contained" : "text"}
                  sx={{ my: 2, color: "white", display: "block" }}
                  LinkComponent={Link}
                  href={route.path}
                >
                  {route.name}
                </Button>
              );
            })}
            {/* {pages.map((page, i) => (
              <Button
                key={page}
                color="secondary"
                onClick={handleCloseNavMenu}
                variant={i === 2 ? "contained" : "text"}
                sx={{ my: 2, color: "white", display: "block" }}
                LinkComponent={Link}
                href="/jobs"
              >
                {page}
              </Button>
            ))} */}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Tu perfil">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default WebsiteAppBar;
