export interface IRoute {
  name: string;
  path: string;
  onNavigation: boolean;
}

export const routes: Record<string, IRoute> = {
  landing: { name: "Inicio", path: "/", onNavigation: false },
  jobs: { name: "Empleos", path: "/jobs", onNavigation: true },
  // contact: { name: "Contacto", path: "/contact", onNavigation: true },
  companies: {
    name: "Publica ofertas gratis !",
    path: "/companies",
    onNavigation: true,
  },
};
